<template>
  <div id="index">
    <img src="./img/lucky_title.png" style="width:76%;margin:0 auto;" />
    <div class="countdownAll">
      <div class="countdown" v-if="luck_info.is_show_end_time == 1">
        距活动结束
        <div class="time">{{ countDownTime }}</div>
      </div>
      <div class="luckdrawNum" v-if="type == 3">
        剩余抽奖次数{{ luck_info.end_num ? luck_info.end_num : 0 }}次
      </div>
    </div>
    <div class="luckyDraw">
      <LuckyWheel
        ref="myLucky"
        :width="luckyWin"
        :height="luckyHeight"
        :prizes="prizes"
        :blocks="blocks"
        :buttons="buttons"
        :defaultConfig="defaultConfig"
        style="margin:0 auto;"
        @start="startCallback"
        @end="endCallback"
      />
      <div class="luckyDraw_record" @click="fShowRecord">抽奖记录</div>
    </div>
    <div class="luckSwiper" v-if="luck_info.is_show_product == 1">
      <div class="swiper-container" style="height:304px;">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in luck_gift" :key="item.id">
            <div>
              <img
                :src="
                  item.image_url
                    ? item.image_url
                    : 'https://www.chuansmart.com/storage/logo/luck_gift.png'
                "
                style="width:120px;height:120px;margin:0 auto;"
              />
            </div>
            <p style="font-size:16px;margin-top:8px;">{{ item.name }}</p>
            <p style="font-size:16px;margin-top:8px;">{{ item.goods_name }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="luckRule luckSwiper" v-if="luck_info.is_show_rule == 1">
      <div class="content">
        <div class="middle" v-if="luck_info.lottery_rule.length > 0">
          <p v-for="(item, i) in luck_info.lottery_rule" :key="i">
            {{ i + 1 }}.{{ item }}
          </p>
        </div>
      </div>
    </div>
    <div class="mask" v-if="showMash">
      <div class="prize_bac" v-if="luck_result.is_no_award != 1">
        <div class="title">恭喜您</div>
        <div class="prize">抽中{{ luck_result.relation_name }}</div>
        <div>
          <img
            :src="
              luck_result.image_url
                ? luck_result.image_url
                : 'https://www.chuansmart.com/storage/logo/luck_gift.png'
            "
          />
        </div>
        <div class="print-btn" v-if="type == 3" @click="fPrint">打印小票</div>
      </div>
      <div class="prize_bac" v-if="luck_result.is_no_award == 1">
        <div class="title">很遗憾</div>
        <div class="prize">本次活动没有中奖</div>
        <div>
          <img src="https://www.chuansmart.com/storage/logo/luck_cry.png" />
        </div>
      </div>
      <div class="closeMash" @click="fCloseGift">x</div>
    </div>
    <div class="recordMask" v-if="isShowRecord">
      <div class="content">
        <div class="filterTime">
          <el-date-picker
            v-model="times"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            @change="selectTime"
          >
          </el-date-picker>
          <div class="closeMash" @click="fCloseRecord">x</div>
        </div>
        <el-table :data="tableData" height="527px" style="width: 100%">
          <el-table-column prop="genre_name" label="奖项"> </el-table-column>
          <el-table-column prop="prize_name" label="奖品"> </el-table-column>
          <el-table-column prop="created_at_time" label="时间">
          </el-table-column>
          <div slot="empty">
            <div>
              <img
                src="https://www.chuansmart.com/storage/logo/no_record.png"
                alt=""
                width="200"
                height="200"
              />
            </div>
            <p style="color:#FFA4A4">暂无相关记录</p>
          </div>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <div class="mask" v-if="showQrcode" style="text-align:center;">
      <div
        style="display:inline-block;padding:48px;text-align:center;background-color:#ffffff;"
      >
        <div class="qrcode" ref="qrCodeUrl"></div>
        <p
          style="height:30px;line-height:30px;margin-top:24px;font-size:28px;color:#333333;"
        >
          微信扫码参与互动
        </p>
      </div>
      <div
        class="close"
        @click="windowClose"
        style="width:60px;height:56px;line-height:56px;margin: 50px auto 0;text-align:center;border-radius: 50%;border:1px solid #ffffff;font-size:26px;color:#ffffff;background-color:rgba(43,43,43,0.4);"
      >
        x
      </div>
    </div>
    <div class="loading" v-if="loading">
      <img
        src="https://www.chuansmart.com/storage/logo/loading01.gif"
        style="width:40%;margin-top:40%;"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swiper from "swiper";
import { formatTime } from "../../utils/index";
import QRCode from "qrcodejs2";

export default {
  name: "luckdraw_ver",
  data() {
    return {
      loading: true,
      showMash: false, //是否显示抽奖的的弹窗
      isShowRecord: false, //是否显示流水记录
      showQrcode: false, //是否显示二维码
      luck_info: {}, //抽奖的信息
      luck_priceid: "", //抽中奖的id
      luck_pricename: "", //抽中奖的名称
      countDownTime: "", //倒计时时间
      times: "", //选择的时间
      luck_result: {}, //转盘的结果
      tableData: [],
      isOverLuckdraw: false, //是否已经点击了转盘抽奖
      start: 0,
      total: 0,
      blocks: [
        {
          padding: "85px",
          imgs: [
            {
              src: "https://www.chuansmart.com/storage/logo/lucky_round.png",
              top: "14px",
              width: "100%",
              height: "100%",
            },
          ],
        },
      ],
      prizes: [],
      defaultConfig: {
        accelerationTime: 1000,
        decelerationTime: 1000,
      },
      buttons: [
        {
          radius: "50%",
          fonts: [
            {
              text: "",
              fontSize: "16px",
              fontColor: "#ffffff",
              top: "24px",
            },
          ],
          imgs: [
            {
              src: "https://www.chuansmart.com/storage/logo/lucky_point3.png",
              top: "-140px",
              width: "176px",
              height: "240px",
              rotate: true,
            },
          ],
        },
      ],
      winWidth: "", //屏幕的宽高
      winHeight: "", //屏幕的宽高
      luckyWin: "600px", //转盘的宽高
      luckyHeight: "600px", //转盘的宽高
    };
  },
  created() {
    this.winWidth = window.screen.width;
    this.winHeight = window.screen.height;
    this.market_id = this.$route.query.market_id;
    this.type_id = this.$route.query.type_id;
    this.type = this.$route.query.type;
    setTimeout(() => {
      // 上一次数据修改引起的dom更新结束后执行回调
      this.$nextTick(() => {
        this.innerBaner();
      });
    }, 1000);
  },
  mounted() {
    this.getInit();
  },
  methods: {
    windowClose() {
      window.location.href = "about:blank";
      window.close();
    },
    //调用安卓打印的方法
    fPrint() {
      let aa = {
        name: this.luck_result.name,
        created_at: this.luck_result.created_at,
      };
      let ac = JSON.stringify(aa);
      window.android.print(ac);
    },
    // 生成二维码
    creatQrCode() {
      let url = this.toMiniUrl + "?market_id=" + this.market_id;
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: url, // 需要转换为二维码的内容
        width: 480,
        height: 480,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    // 记录弹窗选择时间
    selectTime(val) {
      this.start = 0;
      this.fGetRecord();
    },
    // 选择分页
    handleCurrentChange(val) {
      this.start = (val - 1) * 10;
      this.fGetRecord();
    },
    // 关闭抽奖记录
    fShowRecord() {
      this.isShowRecord = true;
      this.times = "";
      this.fGetRecord();
    },
    // 关闭抽奖记录
    fCloseRecord() {
      this.isShowRecord = false;
    },
    // 关闭抽奖结果
    fCloseGift() {
      this.showMash = false;
    },
    // 获取抽奖记录
    fGetRecord() {
      axios
        .post("api/screen/lottery/main", {
          action: "flow.get",
          market_id: this.market_id,
          type_id: this.type_id,
          type: this.type,
          date: this.times,
          start: this.start,
          length: 10,
          // type:this.type
        })
        .then((res) => {
          var data = res.data.data.map((ele) => {
            return {
              ...ele,
              created_at_time: formatTime(
                ele.created_at * 1000,
                "yyyy-MM-dd HH:mm:ss"
              ),
            };
          });
          this.tableData = data;
          this.total = res.data.total;
        });
    },
    // 点击抽奖按钮会触发star回调
    startCallback() {
      if (this.isOverLuckdraw) {
        this.$message.error("已经在抽奖了");
        return false;
      } else {
        this.isOverLuckdraw = true;
      }
      let member = this.luck_info.end_num;
      if (this.luck_info.is_show_image == 1 && member == 0) {
        this.isOverLuckdraw = false;
        this.$message.error("没有抽奖次数了");
        return false;
      }
      axios
        .post("api/screen/lottery/main", {
          action: "to.lottery",
          market_id: this.market_id,
          type_id: this.type_id,
          type: this.type,
          id: this.luck_info.id,
          // type:this.type
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$refs.myLucky.play();
            let that = this;
            that.luck_info.end_num--;
            setTimeout(function() {
              that.getResult(res.data.data.id);
            }, 500);
          } else {
            this.isOverLuckdraw = false;
            this.$message.error(res.data.msg);
          }
        });
    },
    // 获取抽奖结果
    getResult(id) {
      let member = this.luck_info.end_num;
      if (member == 0) {
        this.$message.error("没有抽奖次数了");
        return false;
      }
      axios
        .post("api/screen/lottery/main", {
          action: "to.get",
          market_id: this.market_id,
          type_id: this.type_id,
          type: this.type,
          id: id,
          // type:this.type
        })
        .then((res) => {
          let data = res.data.data;
          let that = this;
          if (res.data.code == 0) {
            this.luck_priceid = data.id;
            var prize_info = this.prizes.filter(
              (ele) => ele.id === this.luck_priceid
            );
            var index = prize_info[0].index;
            this.luck_result = data;
            // 模拟调用接口异步抽奖
            setTimeout(() => {
              // 假设后端返回的中奖索引是0
              that.isOverLuckdraw = false;
              // 调用stop停止旋转并传递中奖索引
              that.$refs.myLucky.stop(index);
            }, 500);
          } else {
            setTimeout(() => {
              // 假设后端返回的中奖索引是0
              // 调用stop停止旋转并传递中奖索引
              that.isOverLuckdraw = false;
              that.$refs.myLucky.stop(index);
            }, 500);
          }
        });
    },
    // 抽奖结束会触发end回调
    endCallback(prize) {
      this.showMash = true;
    },
    // 轮播图
    innerBaner() {
      new Swiper(".swiper-container", {
        loop: true,
        slidesPerView: 5,
        spaceBetween: 30,
        slidesPerGroup: 3,
        autoplay: 3000,
      });
    },
    // 获取初始化数据
    getInit() {
      axios
        .post("api/screen/lottery/main", {
          action: "info.get",
          market_id: this.market_id,
          type_id: this.type_id,
          type: this.type,
          is_end_num: 1,
        })
        .then((res) => {
          if (res.data.code == 0) {
            let data = res.data.data;
            this.luck_info = data.info;
            this.luck_info.end_num = data.skip.end_num;
            var nowTimeStamp = Date.parse(new Date()) / 1000;
            if (data.info.end_time && data.info.is_show_end_time == 1) {
              this.diffrenceTime = data.info.end_time - nowTimeStamp;
              this.fComputCountDown();
            }
            let aa = data.genre.filter((i) => i.is_no_award == 0);
            this.luck_gift = aa.map((ele) => {
              return {
                image_url: ele.image_url,
                name: ele.name,
                goods_name: ele.goods_name,
              };
            });
            this.prizes = data.genre.map((ele, index) => {
              return {
                id: ele.id,
                index: index,
                fonts: [
                  {
                    text: ele.name,
                    top: "45%",
                    fontSize: "22px",
                  },
                ],
                background: index % 2 == 0 ? "#FF9B9B" : "#FFBBBB",
              };
            });
            this.buttons[0].fonts[0].text =
              data.info.is_need_point == 1
                ? "消耗" + data.info.point + "积分"
                : "";
            if (data.skip.is_show_image == 1) {
              this.toMiniUrl = data.skip.url;
              this.showQrcode = true;
              let that = this;
              setTimeout(function() {
                that.creatQrCode();
              }, 1000);
            }
          } else {
            this.$message.error(res.data.msg);
          }
          let that = this;
          setTimeout(function() {
            that.loading = false;
          }, 1000);
        });
    },
    //轮询循环查询
    fActivetyInterval() {
      clearInterval(this.activetyInterval);
      if (this.diffrenceTime == 0) {
        this.countDownTime = "0天 00:00:00";
        return false;
      }
      let that = this;
      that.activetyInterval = setInterval(function() {
        that.diffrenceTime--;
        that.fComputCountDown();
      }, 1000);
    },
    // 计算倒计时
    fComputCountDown() {
      let time = this.diffrenceTime;
      var day = parseInt(time / 60 / 60 / 24);
      var hour = parseInt((time / 60 / 60) % 24);
      var minute = parseInt((time / 60) % 60);
      var seconds = parseInt(time % 60);
      hour = hour < 10 ? "0" + hour : hour;
      minute = minute < 10 ? "0" + minute : minute;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      this.countDownTime =
        day > 0
          ? day + "天 " + hour + ":" + minute + ":" + seconds
          : hour + ":" + minute + ":" + seconds;
      this.fActivetyInterval();
    },
  },
};
</script>

<style lang="scss">
#index {
  width: 100%;
  height: 100vh;
  min-height: 100%;
  padding-top: 160px;
  background: url(https://www.chuansmart.com/storage/logo/lucky_bg.jpg)
    no-repeat;
  background-size: 100% 100%;
  text-align: center;
  .loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ffffff;
  }
  .luckyDraw {
    position: relative;
    .luckyDraw_record {
      position: absolute;
      top: 50px;
      right: 0;
      width: 160px;
      height: 56px;
      line-height: 56px;
      text-align: center;
      color: #ffffff;
      font-size: 20px;
      background: url(https://www.chuansmart.com/storage/logo/prize_more.png)
        no-repeat;
      background-size: 100% 100%;
    }
  }
  .countdownAll {
    display: flex;
    justify-content: center;
    align-items: center;
    .luckdrawNum {
      height: 34px;
      line-height: 34px;
      border-radius: 14px;
      margin-left: 40px;
      font-size: 20px;
      color: #ffffff;
      padding: 0 16px;
      background-color: rgba($color: #000000, $alpha: 0.1);
    }
  }
  .countdown {
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    .time {
      height: 34px;
      line-height: 34px;
      border-radius: 14px;
      padding: 0 16px;
      margin-left: 12px;
      background-color: rgba($color: #000000, $alpha: 0.1);
    }
  }
  .qrcode img {
    margin: 0 auto;
  }
  .mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding-top: 40%;
    background: rgba($color: #000000, $alpha: 0.4);
    z-index: 999;
    .prize_bac {
      width: 840px;
      height: 862px;
      margin: 0 auto;
      padding-top: 35%;
      text-align: center;
      color: #ffffff;
      background: url(https://www.chuansmart.com/storage/logo/luck_open_gift.png)
        no-repeat;
      background-size: 100% 100%;
      .title {
        height: 50px;
        font-size: 48px;
      }
      .prize {
        height: 40px;
        font-size: 36px;
      }
      .print-btn {
        width: 272px;
        height: 72px;
        line-height: 72px;
        margin: 0 auto;
        text-align: center;
        border-radius: 8px;
        font-size: 36px;
        background-color: #ffffff;
        color: #ec9024;
        margin-top: 152px;
      }
      img {
        width: 120px;
        height: 120px;
      }
    }
    .closeMash {
      width: 60px;
      height: 60px;
      line-height: 60px;
      margin: 20px auto 0;
      font-size: 40px;
      text-align: center;
      font-weight: normal;
      border: 1px solid #ffffff;
      border-radius: 50%;
      color: #ffffff;
    }
  }
  .recordMask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding-top: 40%;
    background: rgba($color: #000000, $alpha: 0.4);
    z-index: 999;
    .content {
      height: 770px;
      padding: 52px;
      margin-left: 40px;
      margin-right: 40px;
      background: url(https://www.chuansmart.com/storage/logo/prize_record.png)
        no-repeat;
      background-size: 100% 100%;
      .filterTime {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .el-input__inner {
          color: #ffffff;
          background-color: #970404;
          border: none;
        }
        .closeMash {
          width: 50px;
          height: 50px;
          line-height: 50px;
          font-size: 30px;
          text-align: center;
          font-weight: normal;
          border-radius: 50%;
          color: #ffffff;
          background: rgba($color: #000000, $alpha: 0.1);
        }
      }
      .el-table::before {
        height: 0;
      }
      .el-table {
        font-size: 20px;
        color: #ffa4a4;
        background-color: #970404;
        border: none;
        th.el-table__cell {
          text-align: center;
          color: #ffa4a4;
          background-color: #a60000;
          border: none;
        }
        th.el-table__cell:last-child {
          text-align: right;
        }
        tr {
          background-color: #970404;
          border: none;
        }
        td.el-table__cell {
          text-align: center;
          border-bottom: 1px solid #8b0000;
        }
        td.el-table__cell:last-child {
          text-align: right;
        }
        .el-table__empty-text {
          line-height: 0;
        }
      }
      .el-pagination {
        text-align: right;
        margin-top: 20px;
      }
      .el-pagination.is-background .btn-next,
      .el-pagination.is-background .btn-prev,
      .el-pagination.is-background .el-pager li {
        color: #cfcfcf;
        background-color: #8b0000;
        font-size: 20px;
      }
      .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #d42a2a;
        color: #ffffff;
      }
    }
  }
  .luckSwiper {
    padding: 0 40px;
  }
  .swiper-container {
    color: #ffffff;
    padding-top: 80px;
    background: url(https://www.chuansmart.com/storage/logo/lucky_prize.png)
      no-repeat;
    background-size: 100% 100%;
  }
  .luckRule {
    margin-top: 32px;
    .content {
      height: 304px;
      color: #ffffff;
      padding: 70px 54px 44px;
      overflow: hidden;
      line-height: 22px;
      font-size: 16px;
      text-align: left;
      background: url(https://www.chuansmart.com/storage/logo/lucky_rule.png)
        no-repeat;
      background-size: 100% 100%;
      .middle {
        height: 200px;
        overflow-y: auto;
      }
    }
  }
}
// @import "../assets/scss/index.scss";
</style>
